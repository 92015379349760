.ant-modal-content {
  border-radius: 8px;
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }
}

.ant-modal-confirm .ant-modal-body {
  padding:20px !important;
}

.ant-modal-confirm-title {
  font-weight: 700 !important;
}

.ant-modal-confirm-content {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #F0F0F0;
  border-top: 1px solid #F0F0F0;;
}