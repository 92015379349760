.question-conditions-group {
  margin-bottom: 12px;

  .ant-input-group-addon {
    width: 10%;
    display: inline-block;
    border-radius: 16px 0 0 16px !important;
    line-height: 30px;
    transition: all 0.3s;
  }
  .ant-select {
    width: 30%;
    display: inline-block;
    .ant-select-selector {
      border-radius: 0 0 0 0 !important;
      border: 1px solid #d9d9d9 !important;
    }
  }
  .ant-input {
    width: 60%;
    display: inline-block;
    border-radius: 0 16px 16px 0 !important;
  }
}
