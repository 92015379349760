.declaration-check-list {
  margin-bottom: 20px;
  width: 100%;

  ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }
  .check-list {
    & > li {
      margin-bottom: 5px;
    }
  }
  .description-list {
    font-size: 0.8em;
    color: #21252980;
    margin-bottom: 5px;
  }
  .checkbox-list {
    &__item {
      font-size: 0.95em;
      color: #21252980;
    }
  }
  .btn-group {
    margin: 2px 0;
    width: 100%;
    & > * {
      width: 50%;
    }

    // override antd
    &__item:nth-child(1) {
      border-radius: 3px 0 0 3px;
    }
    &__item:nth-child(2) {
      border-radius: 0 3px 3px 0;
    }
    &__item {
      & > span:last-child {
        margin: auto;
      }
    }
  }
}

.ant-select-dropdown {
  .others-option {
    .ant-input-search .ant-input-group input.ant-input {
      border-radius: 0 !important;
    }
    .ant-input-search
      > .ant-input-group
      > .ant-input-group-addon:last-child
      .ant-input-search-button {
      border-radius: 0 !important;
      padding: 0;
    }
  }
}

