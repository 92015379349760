.ant-select:not(.ant-select-disabled):hover,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    border-color: @main-color;
    box-shadow: 0 0 0 2px transparent;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 0px;
}
