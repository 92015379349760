@import '../variables.less';

.go-entry-logo {
  &__img {
    width: auto;
    height: 60px;
  }
  &__title {
    font-size: 30px;
    // color: @main-color;
    font-size: 30px;
    color: rgb(102, 196, 172);
    position: relative;
    bottom: -7px;
    left: 5px;
  }
}
