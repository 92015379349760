.ant-input:active,
.ant-input:hover,
.ant-input:focus,
.ant-input-focused {
  border-color: @main-color;
  box-shadow: 0 0 0 2px transparent;

  &[type='password'] {
    box-shadow: none;
  }
}

.ant-input-number:active,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: @main-color;
  box-shadow: 0 0 0 2px transparent;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: @main-color;
  box-shadow: 0 0 0 2px transparent;
}

.ant-input-affix-wrapper:hover {
  border-color: @main-color;
  box-shadow: 0 0 0 2px transparent;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: @main-color;
  box-shadow: 0 0 0 2px transparent;
}
