.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: @main-color;
}

.autocomplete-search {
  .ant-select-selector {
    .ant-input-group {
      input {
        border-radius: 16px 0 0 16px;
        padding-left: 16px;
        // border-color: @main-color;
        outline: none;
        box-shadow: none;
        &::hover {
          border-color: @main-color;
        }
      }
      .ant-input-group-addon {
        button.ant-btn {
          border-radius: 0 16px 16px 0;
          border-color: #d9d9d9;
          width: 40px;
          outline: none;
          box-shadow: none;

          &:hover,
          &:focus {
            border-color: @main-color;
          }
        }
      }
    }
  }

  &.ant-select-focused {
    .ant-select-selector {
      .ant-input-group {
        input {
          border-color: @main-color;
        }
        .ant-input-group-addon {
          button.ant-btn {
            border-color: @main-color;
          }
        }
      }
    }
  }
}
