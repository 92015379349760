@import './../variables.less';

.download-banner {
  position: relative;
  background: @default-linear;
  color: white;
  padding: 16px;
  &__info {
    span {
      font-size: 0.65rem;
    }
  }

  &__download-btn {
    width: 100%;
    padding: 7px;
    font-size: 14px;
    font-weight: 500;
    max-width: 240px;
    background-color: #fff;
    border-radius: 30px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;  
    a {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  &__download-btn-group {
    line-height: 0;
    a {
      display: inline-block;
      height: 50px;
    }
    svg {
      width: auto;
      height: inherit;
    }
  }

  &__bottom {
    margin-top: 16px;
  }

  .app-logo {
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }

  .close-btn {
    position: absolute;
    top: 7px;
    right: 7px;
    width: 12px;
    height: auto;
    color: white;
  }
}

// desk
@media only screen and (min-width: 413px) {
  .download-banner {
    &__top {
      align-items: center;
    }
    .app-logo {
      margin-right: 10px;
    }

  }
}

// mobile
@media only screen and (max-width: 413px) {
  .download-banner {
    &__download-btn-group {
      line-height: 0;
      a {
        display: inline-block;
        height: 30px;
      }
      svg {
        width: auto;
        height: inherit;
      }
    }

  }
}