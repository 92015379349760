@import './../variables.less';

.enrol-page {
  max-width: 400px;
  min-height: 200px;

  .card {
    padding: 50px;
  }

  .numberic-input {
    width: 40px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid @main-color;
    box-shadow: none !important;
    text-align: center;

    &.error {
      border-bottom: 2px solid @error-color;
      color: @error-color;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .btn-submit {
    width: 200px;
    color: white;
    background-color: @main-color;
    background: @main-color;
    border: none;
    --antd-wave-shadow-color: @main-color;
  }
}
