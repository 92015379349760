.ant-btn-primary {
  border-color: @main-color;
  border-radius: 4px;

  color: @main-color;
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    border-color: @main-color;
    color: @main-color;
    color: #fff;
  }
}

// .ant-btn-dangerous {
//   color: #fff;
//   border-color: transparent;
//   background-color: #ff4d4f;

//   &:hover,
//   &:focus,
//   &:active {
//     color: #fff;
//     border-color: transparent;
//     background-color: #ff4d4f;
//   }
// }

.btn-create {
  border-radius: 4px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 700;
  color: #fff;
  background: @main-color;
  border-color: @main-color;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background: @hover-color;
    font-weight: 700;
    border-color: @hover-color;
  }
}

.btn-edit {
  // border-radius: 16px;
  padding-left: 30px;
  padding-right: 30px;
  // background: @check-in-color;
  background: @main-color;
  // border-color: @main-color;
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    // border-color: @check-in-color;
    // color: @check-in-color;
    background: #3861c7;
    color: #fff;
  }
}

.btn-delete {
  border-color: #ff6565;
  // background: #ff6565;
  padding-left: 30px;
  padding-right: 30px;
  color: #ff6565;
  border-radius: 16px;
  background: transparent;

  &:hover,
  &:active,
  &:focus {
    color: #ff6565;
    background: transparent;
    border-color: #ff6565;
  }
}

.btn-default {
  border-color: @main-color;
  color: @main-color;
  font-weight: 700;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 4px;

  &:hover,
  &:active,
  &:focus {
    font-weight: 700;
    border-color: @hover-color;
    color: @hover-color;
  }
}

.btn-download-disabled {
  opacity: 0.5;
}

.btn-pending {
  border-color: #ffa940;
  color: #ffa940;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 16px;

  &:hover,
  &:active,
  &:focus {
    border-color: #ffa940;
    color: #ffa940;
  }
}

.btn-save {
  width: 100px;
  color: white;
  background-color: #2C4B9C;
  border-radius: 5px;
}

.btn-delete-custom {
  width: 100px;
  color: white !important;
  background-color: #2C4B9C !important;
  border-radius: 5px;
}

.btn-cancel {
  width: 100px;
  color: #2C4B9C;
  border-color: #2C4B9C;
  border-radius: 5px;
}

