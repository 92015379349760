@import './../variables.less';

.main-page {
  & > * {
    margin-bottom: 60px;
  }
  .loading-effect {
    // custom antd
    margin-top: 20vh;
    .ant-spin-dot-item {
      background-color: @main-color;
    }
  }
  .location-card {
    max-width: 500px;
    min-height: 200px;
  }
  .location {
    &__icon {
      width: auto;
      height: 50px;
      margin-right: 3px;
      color: @main-color;
    }
    &__status {
      span {
        line-height: 1.1;
        font-size: @text-size-md;
      }
      b {
        font-size: @text-size-lg;
      }
    }
    &__name {
      font-size: 20px;
      text-align: center;
    }
  }
  .actions {
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 60vw;
      // max-width: 300px;
      // height: 50px;
      // font-size: @text-size-xl;

      width: 177px;
      height: 37px;
      border-radius: 4px;
      font-weight: 700;
      // & > *:first-child {
      //   margin-right: 15px;
      // }
    }
  }
}