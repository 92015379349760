@import '../variables.less';

.four-zero-four-page {
  & > * {
    &:first-child {
      margin-bottom: 30px;
    }
  }
  .error-message {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    margin-bottom: 30px;
    text-align: center;
  }
  .error-image {
    color: @main-color;
  }
  .fix-steps {
    list-style: none;
    padding: 0;
    li {
      flex: none;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      div {
        flex: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background: @main-color;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
        color: white;
      }
      p {
        margin: 0;
      }
    }
  }
}
