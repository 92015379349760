@import '~antd/dist/antd.less';
@import 'theme/customize.less';
@import './theme/utils.less';
@import './theme/style.less';

// @font-face {
//   font-family: Poppins;
//   src: url('./assets/fonts/Poppins-Regular.ttf');
// }

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,700;0,800;1,400;1,500;1,700;1,800&family=Noto+Sans:ital,wght@0,300;0,500;0,700;1,300;1,500;1,700&display=swap');

.App {
  // font-family: Poppins;
  font-family: Lato;
}

