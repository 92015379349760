@import '../../../theme/variables.less';

.user-table .ant-table-content {
    overflow: auto hidden;
}

.user-table .ant-table-content table {
    width: 500px;
    min-width: 100%;
    table-layout: fixed !important;
}

.user-table .ant-table-content table th {
    font-weight: 700;
    font-size: 14px;
}

.user-table tbody td {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    word-break: break-all;
}

.user-table thead th {
    white-space: nowrap;
    word-break: break-word;
    word-break: break-all;
}

.delete-button-plain {
    font-weight: 500 !important;
    border-color: transparent !important;
    color: @error-color !important;
}

