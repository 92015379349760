@font-face {
    font-family: Lato;
    src: url('../../../assets/fonts/Lato-Regular.ttf');
  }

* {
    font-family: Lato;
}

.visitor-list-page-layout {
.top-bar {
    color: white;
    margin: 0;
    padding: 20px;
    background-color: #2C4B9C;
    font-size: 16px;
    font-weight: 900;
}

.top-bar img {
    height: 36px;
}

.top-bar p {
    color: white;
    text-decoration: none;
    float: right;
    padding: 6px 0 !important;
}

.title-box {
    margin: 20px 12px 12px;
    height: 60px;
}

.title-font {
    font-size: 24px;
    font-weight: 900;
}

.label-box {
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
}

.list-container {
    width: 100%;
    margin: 20px 0 0 0;
    height: calc(100% - 176px);
}

.tabs-container {
    margin: 0;
}

.no-data-box {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #8392AB;
    padding: calc(100% / 3) 0;
}

.no-data-box img {
    height: 36px;
}

.list-scrollable {
    background-color: '#F5F5F5';
    overflow: auto !important;
    overscroll-behavior: contain;
}

.primary-button {
    float: right;
    background-color: #2C4B9C !important;
    border-color: transparent !important;
    border-radius: 4px !important;
    padding: 4px 15px !important;
}

.ant-layout-content {
    border-radius: 0px !important;
}

.ant-select {
    width: 50%;
}

.ant-select-selector {
    border-radius: 4px !important;
    box-shadow: none !important;
}

.ant-select-selector:hover, .ant-select-selector:focus-within {
    border-color: #80A0E1 !important;
  }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #AEC8F5 !important;
}

.ant-select-dropdown {
    border-radius: 8px !important;
}

.ant-tabs-tab {
    color: #8392AB !important;
    font-weight: 700;
    padding: 10px 32px !important;
    margin: 0 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2C4B9C !important; 
 }

 .ant-tabs-ink-bar {
    background: #2C4B9C !important;
  }
  
.ant-tabs-nav-wrap {
    width: 100%;
}

.ant-layout-footer {
    width: 100%;
    padding: 20px 12px !important;
    background-color: white !important;
    position: absolute;
    bottom: 0; left: 0; right: 0;
    border-top: 2px solid rgba(0, 0, 0, 0.2) !important;
}

.ant-btn[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.ant-btn-primary img {
    height: 12px;
    padding-left: 10px;
}

.ant-checkbox-wrapper {
    padding: 6px 0;
}

.ant-checkbox-inner  {
    border-color: black !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2C4B9C !important;
    border-color: #2C4B9C !important;
  }

.ant-checkbox-checked::after {
    border: none !important;
}

.ant-modal{
    position: relative;
    top: 30% !important;
}

.ant-modal-content {
    border-radius: 4px;
}

.ant-modal-header {
    border-bottom: none !important;
}

.ant-modal-header .ant-modal-title {
    font-size: 24px;
    font-weight: 900;
}

.ant-modal-close {
    visibility: hidden;
}

.ant-modal-body {
    padding: 12px 24px 8px 24px !important;
}

.ant-modal-body {
    padding-bottom: 0;
}

.ant-modal-footer {
    border-top: none !important;
}

.ant-modal-footer .ant-btn {
    background-color: transparent !important;
    border: none !important;
    color: #2C4B9C !important;
    font-size: 14px;
    font-weight: 700;
    padding-left: 4px;
    padding-right: 4px;
}
}