@import '../../../theme/variables.less';

.title-search-bar {
    width: 250px !important;
    padding-top: 20px;
    padding-bottom: 20px;
}

.location-table .ant-table-content {
    overflow: auto hidden;
}

.location-table .ant-table-content table {
    width: 500px;
    min-width: 100%;
    table-layout: fixed !important;
}

.location-table .ant-table-content table th {
    font-weight: 700;
    font-size: 14px;
}

.location-table tbody td {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    word-break: break-all;
}

.location-table thead th {
    white-space: nowrap;
    word-break: break-word;
    word-break: break-all;
}

.link-button-plain {
    font-weight: 500 !important;
    border-color: transparent !important;
    color: @main-color !important
}

.danger-color {
    color: @error-color;
}

.btn-tag {
    margin-right: 3px;
    overflow: 'hidden';
    width: '100%';
}

.tag-color {
    color: @main-color;
}

.tag-color-error {
    color: @error-color;
}

.location-modal .ant-select .ant-select-selector, .location-modal .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px !important;
}

.location-modal .ant-col-6 {
    flex: 0 0 15% !important;
}

.location-modal .ant-col-18 {
    max-width: 100% !important;
}

.location-room-modal .ant-form-item {
    margin-bottom: 0 !important;
}

.location-modal .ant-modal-title {
    font-weight: 700 !important;
}

.location-modal .ant-btn {
    width: 100px;
}

.location-modal .ant-descriptions-row > td {
    padding-bottom: 4px !important;
}

.location-modal .ant-tag-red {
    color: #410002 !important;
    background: #FFDAD6 !important;
    border-color: #BA1A1A !important;
}

.location-modal .ant-tag-red .ant-tag-close-icon {
    color: #410002;
}

.title-alert-box {
    width: 100%;
    border: 2px solid #FFE58F;
    background-color: #FFFBE6;
    padding: 10px 20px;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
}

.title-alert-box button {
    border: none;
    font-weight: normal;
    padding: 0;
    text-decoration: underline !important;

    &:hover,
    &:active,
    &:focus {
        color: @main-color;
    }
}
