
.ant-message-notice-content {
    text-align: left;
    position: absolute;
    left: 80vw;
    margin-top: 3vh;
    margin-right: 2vw;
    padding: 0px;
  }
  
  .ant-message-notice-content .ant-message-error {
    background-color: #ffd2d2;
    color: #701919;
    padding: 20px;
  }

  .ant-message-notice-content .ant-message-success {
    color: #B9FAD2;
    background-color: #166341;
    padding: 20px;
  }


