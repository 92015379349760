.d {
  &-inline-block {
    display: inline-block;
  }
}
// flex box
.d-flex {
  display: flex;
  &.justify-content {
    &-center {
      justify-content: center;
    }
    &-between {
      justify-content: space-between;
    }
  }

  &.align-items {
    &-center {
      align-items: center;
    }
    &-end {
      align-items: flex-end;
    }
  }

  &.flex {
    &-column {
      flex-direction: column;
    }
  }
}

// container
.container {
  width: 80%;
  margin: auto;
  &-fluid {
    width: 100%;
  }
}

.border {
  &-primary {
    border-color: @main-color;
  }
  &-none {
    border: none;
  }
}

// button
.btn {
  &-active {
    color: white;
    background-color: @main-color;
    border-color: transparent;
  }
}

// image
.tabs {
  &-1 {
    margin-left: 20px;
  }
  &-2 {
    margin-left: 40px;
  }
}

.text {
  &-center {
    text-align: center;
  }
}

// z-index
.z-1 {
  z-index: 1;
}
.z-10 {
  z-index: 10;
}
.z-100 {
  z-index: 100;
}
