.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f9f9f9;
}
.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

// custom antd
// .entrance-table {
//   width: 100%;
//   border: 1px solid #f0f0f0;
//   .ant-table.ant-table-small {
//     margin: 0 !important;
//   }
// }
// .location-table {
//   .ant-table-expanded-row > td {
//     background: inherit;
//   }
// }

.ant-table-expanded-row {
  & > .ant-table-cell {
    // background-color: #f5f5f5 !important;

    .ant-table-wrapper {
      margin-bottom: 20px;

      .ant-spin-container > .ant-table {
        // border-left: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
      }
    }
  }
}

ul.ant-pagination.ant-table-pagination {
  .ant-pagination-item-link:hover,
  .ant-pagination-item-active,
  .ant-pagination-item:hover {
    border-color: @main-color;
    color: @main-color;

    & > a {
      color: @main-color;
    }
  }
}

.ant-table-row-expand-icon {
  &:focus,
  &:hover {
    color: @main-color;
  }
  &:active {
    color: @main-color;
  }
}
