.declaration-form {
  width: 100%;

  input,
  textarea,
  .ant-select-selector {
    min-height: 50px !important;
    // height: 50px !important;
    border-radius: 16px !important;
    border: 1px solid #526480 !important;
    background-color: #ffffff !important;
    font-size: 16px;
    color: black;
  }

  // override antd
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  input:hover, input:focus-within,
  .ant-select-selector:hover, .ant-select-selector:focus-within {
    border: 1px solid @main-color !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 167, 233, 0);
    box-shadow: 0 0 0 2px rgba(87,168,233, 0);
  }

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.5) !important;
    background-color: #D9D9D9 !important;
  }

}
