.ant-radio-checked .ant-radio-inner {
  border-color: @main-color;
  &::after {
    background-color: @main-color;
  }
}

.ant-picker-active-bar {
  background-color: @main-color !important;
}

.ant-radio-button-wrapper:hover {
  color: @main-color !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: @main-color !important;
  border-color: @main-color !important;

  &::before {
    background-color: @main-color !important;
  }
}

.ant-radio-button-wrapper:focus-within {
  border-color: @main-color !important;
}