// pages
@import './pages/_MainPage.less';
@import './pages/_DeclarationPage.less';
@import './pages/_CompletePage.less';
@import './pages/_FourZeroFourPage.less';
@import './pages/_EnrolPage.less';
@import './pages/_UserManagementPage.less';

// components
@import './components/_Header.less';
@import './components/_Card.less';
@import './components/_DeclarationForm.less';
@import './components/_Policy.less';
@import './components/_GovEntryLogo.less';
@import './components/_DownloadBanner.less';

//elements
@import './elements/_AutoComplete.less';
@import './elements/_Border.less';
@import './elements/_Button.less';
@import './elements/_Card.less';
@import './elements/_Checkbox.less';
@import './elements/_Descriptions';
@import './elements/_Input.less';
@import './elements/_InputGroup.less';
@import './elements/_Link.less';
@import './elements/_Modal.less';
@import './elements/_Picker.less';
@import './elements/_Radio.less';
@import './elements/_Search.less';
@import './elements/_Select.less';
@import './elements/_Switch.less';
@import './elements/_Table.less';
@import './elements/_Tabs.less';

html {
  --antd-wave-shadow-color: @main-color;
}

.ant-spin-dot-item {
  background-color: @main-color;
}

.ant-tag {
  border-radius: 16px !important;
  background-color: @main-color;
  color: #fff;
}

.ant-tag-close-icon {
  color: #fff;
}
