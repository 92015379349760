.space-align-container {
  flex: none;
}

.menu-item-cus {
  svg {
    vertical-align: middle;
    display: inline-block;
  }
  span {
    vertical-align: middle;
    display: inline-block;
    margin-left: 10px;
  }
}

.layout-sider {
  padding-top: 64px;
  box-shadow: 0px 1px 6px rgba(33, 33, 33, 0.38);
  -webkit-box-shadow: 0px 1px 6px rgba(33, 33, 33, 0.38);
  -moz-box-shadow: 0px 1px 6px rgba(33, 33, 33, 0.38);
  -o-box-shadow: 0px 1px 6px rgba(33, 33, 33, 0.38);
  -ms-box-shadow: 0px 1px 6px rgba(33, 33, 33, 0.38);
}

