.ant-input-search {
  .ant-input-group {
    input.ant-input {
      border-radius: 2px 0 0 2px !important;
    }
    .ant-input-group-addon {
      button.ant-btn {
        width: 40px;
        border-color: #d9d9d9;
        border-radius: 0 2px 2px 0 !important;

        &:hover,
        &:focus {
          border-color: @main-color;
        }
      }
    }
  }
}

.ant-input-search
  .ant-input:hover
  + .ant-input-group-addon
  .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search
  .ant-input:focus
  + .ant-input-group-addon
  .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: @main-color;
}
