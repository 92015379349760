@import './../variables.less';

.declaration-page {
  width: 90%;

  .form-card {
    max-width: 500px;
    & > *:nth-child(1) {
      margin-bottom: 30px;
    }
    & > *:nth-child(2) {
      margin-bottom: 30px;
    }
  }

  .building-name {
    font-size: 24px;
    p {
      font-weight: 600;
      text-align: center;
      margin: 0;
    }
    span {
      font-size: 0.7em;
    }
  }

  .remember-panel {
    width: 100%;
    & > span {
      font-size: @text-size-lg;
    }
    button.ant-switch-checked {
      background-color: @main-color;
    }
  }

  .bottom-panel {
    height: 12vh;

    div {
      box-shadow: 0 -1px 5px #0000001a;
      background: white;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      height: 68px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60vw;
      max-width: 300px;
      height: 50px;
      font-size: @text-size-xl;
      & > *:first-child {
        margin-right: 15px;
      }
      &:disabled {
        background-color: #f5f5f5;
      }
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 2px;
  }
}

.btn-check-in {
  color: white;
  border-color: @main-color;
  background-color: @main-color;

  &:hover,
  &:active,
  &:enabled {
    color: white;
    border-color: @main-color;
    background-color: @main-color;
  }
}

.btn-check-out {
  color: @main-color;
  border-color: @main-color;
  background-color: transparent;

  &:hover,
  &:active,
  &:enabled {
    color: @main-color;
    border-color: @main-color;
    background-color: transparent;
  }
}

.dangerous-popup {
  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-body {
    padding: 30px;
  }
}
