.ant-card {
  border-radius: 16px;
}

.ant-layout-content {
  border-radius: 16px;
}

.ant-tabs-tabpane {
  border-radius: 0 16px 16px 16px !important;
}

.ant-tabs-tab:focus,
.ant-tabs-tab:hover,
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: @main-color;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 10px 10px 0 0 !important;
  margin: 0 !important;
}
