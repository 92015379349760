.ant-layout-header {
  height: 60px;
}

.header_logo {
  width: 180px;
  height: 30px;
  //   background: rgba(255, 255, 255, 0.2);
  margin: 15px 28px 15px 25px;
  display: flex;
}

