.download-link {
  white-space: nowrap;
  color: @main-color;
  margin-right: 10px;
  text-decoration: underline;

  &:hover {
    color: @main-color;
    text-decoration: none;
  }
}
