.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
}

.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0;
}

.ant-tabs-tabpane {
  padding: 15px;
  background-color: #fff;
  padding-top: 25px;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active,
.ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: @main-color;
}

.ant-tabs-ink-bar {
  background: @main-color;
}
