.error-page__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  min-height: 500px;
}
.error-page__left {
  float: right;
  width: 100%;
  max-width: 430px;
  height: 360px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}

.error-page__imageblock {
  flex: 0 0 50%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
}

.error-page__content {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.error-page__content h1 {
  margin-bottom: 24px;
  color: #434e59;
  font-weight: 600;
  font-size: 72px;
  line-height: 72px;
}
.error-page__content .error-page__desc {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}
