@import '../variables.less';

.complete-page {
  position: relative;
  & > * {
    margin-bottom: 40px;
  }
  &__bottom {
    p {
      text-align: center;
      &:first-child {
        font-weight: 500;
        margin-bottom: 5px;
        font-size: @text-size-lg;
      }
      &:last-child {
        font-size: @text-size-md;
        font-weight: 400;
        margin-bottom: 30px;
        color: #909090;
      }
    }
  }
  .check-in {
    background-color: @check-in-color;
  }
  .check-out {
    background-color: @check-out-color;
  }
  .user-check {
    position: absolute;
    width: 130px;
    height: 50px;
    border-radius: 16px;
    border: 3px solid #fff;
    top: -25px;
    span {
      margin-right: 5px;
      font-size: 32px;
      color: #fff;
      line-height: 1;
      font-weight: 600;
    }
    .user-icon {
      width: auto;
      height: 32px;
    }
  }
  .result-card {
    max-width: 500px;
    padding: 0;
    overflow: hidden;
    &__top {
      width: 100%;
      height: 80px;
      padding: 5px 0;
      span {
        font-size: 26px;
        font-weight: bold;
        color: white;
      }
    }
  }
  .check-time {
    margin: 20px 0;
    b {
      color: #000;
      font-weight: bold;
      font-size: 36px;
      text-align: center;
      line-height: 1;
    }
  }
  .building-info {
    font-size: 20px;
    margin-bottom: 10px;
    & > div {
      line-height: 1;
    }
    p {
      font-weight: 500;
      margin-bottom: 0;
    }
    span {
      font-size: 0.7em;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60vw;
    max-width: 300px;
    height: 50px;
    font-size: @text-size-xl;
    background-color: transparent;
    & > *:first-child {
      margin-right: 15px;
    }
  }
}
