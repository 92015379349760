@import '../variables.less';

.header {
  margin-bottom: 40px;
  &__info {
    background-color: @layout-header-background;
    padding: 5px;
    margin-bottom: 20px;

    & > * {
      margin-right: 5px;
    }

    span {
      font-size: @text-size-sm;
    }
  }

  &__bottom-bar {
    margin-top: 40px;
    position: relative;
  }

  .back-btn {
    position: fixed;
    top: 20px;
    left: 1rem;
    bottom: 0;
    margin-left: 10px;
    cursor: pointer;

    &__icon {
      height: 15px;
      width: auto;
      margin: 0 15px;
    }

    &__name {
      font-size: @text-size-md;
    }
  }
}
